<template>
  <div>
    <HeaderMultiPageRoute>
      <div slot="logo" class="d-flex logos">
        <img class="logo-img" src="../assets/img/logo/logo.svg" />
        <img class="logo-sign-img" src="../assets/img/logo/logo-sign.svg" />
      </div>
    </HeaderMultiPageRoute>

    <hooper ref="carousel" :settings="settings">
      <slide v-for="(slider, i) in sliderContent" :key="i">
        <div
          class="swiper-slide section slider-area fullscreen-slider fullscreen d-flex align-items-center bg_image award-content"
          :style="{ backgroundImage: 'url(' + slider.src + ')' }"
        >
          <v-container>
            <v-row class="d-flex fullscreen" align="center">
              <v-col cols="12">
                <div class="content text-center wrap-text">
                  <h2>{{ slider.title }}</h2>
                  <p class="subtitle wrap-text text-shadow" style="max-width: 960px; display: inline-block;">
                    {{ slider.desc }}
                  </p>
                  <div style="font-size: 1rem;" v-if="slider.position==='our-story'">
                    <p class="subtitle wrap-text text-shadow">{{ slider.memo }}</p>
                  </div>
                  <ul class="rn-award-list">
                    <li v-for="(item, i) in slider.awardList" :key="i" style="display: inline-block; width: 100%;">
                      <p class="wrap-text text-shadow"
                        >{{ item.name }}</p
                      >
                      <p class="wrap-text text-shadow" style="font-size: 0.80rem; line-height: 24px; max-width: 400px; display: inline-block;">
                        {{ item.description }}
                      </p>
                    </li>
                  </ul>

                  <ul class="rn-award-list">
                    <li v-for="(item, i) in slider.contactInfo" :key="i">
                      <p v-if="item.name === 'Address'" class="text-shadow" style="margin-top: 40px;">
                        <span style="font-size: 1.2rem">BSM株式会社</span><br>
                          <span style="margin-top: 10px; font-size: 0.8rem; display: block;">{{ item.desc }}</span>
                        <a href="https://goo.gl/maps/noYokPoxNja5LHss8" target="_blank" style="margin-top: 5px;">
                          <i v-html="iconSvg('map')" style="margin-right: 5px;"/> 
                          <span style="position:relative; top: -6px;font-size: 0.8rem;">マップを開く</span>
                        </a>
                      </p>
                       <a :href="item.to" target="_blank">
                        <p v-if="item.name === 'Mail'" style="font-size: 0.9rem" class="text-shadow">
                          <i v-html="iconSvg('mail')" style="margin-right: 5px;"/> 
                          <span style="position:relative; top: -6px; font-size: 0.8rem;">フォームを開く</span>
                        </p>
                      </a>
                    </li>
                  </ul>
                </div>
              </v-col>
            </v-row>
          </v-container>
        </div>
        <!-- End Hero Area -->
      </slide>
      <hooper-pagination slot="hooper-addons"></hooper-pagination>
    </hooper>
  </div>
</template>

<script>
  import HeaderMultiPageRoute from "../components/header/HeaderMultiPageRoute";
  import { Hooper, Slide, Pagination as HooperPagination } from "hooper";
  import feather from "feather-icons";

  export default {
    components: {
      HeaderMultiPageRoute,
      Hooper,
      Slide,
      HooperPagination,
    },
    data() {
      return {
        sliderContent: [
          {
            src: require("../assets/img/slider-6/slider-1.jpg"),
            position: "home",
            title: "Be Simple",
            desc: ` 実直で俊敏に、目に見える成果を`,
          },
          {
            src: require("../assets/img/slider-6/slider-2.jpg"),
            position: "our-story",
            title: "Our Story",
            desc: ` BSMは2007年の事業開始より、専門家がパートナ企業と連携し最新ソリューションをローコストで提供しております。`,
            memo: '2024年1月に株式会社へ組織変更を行いました。'
          },
          {
            src: require("../assets/img/slider-6/slider-3.jpg"),
            position: "services",
            title: "Services",
            desc: ``,
            awardList: [
              {
                name: "ハイブリッドアプリケーション開発",
                description: ` 高品質・高効率な開発でビジネスをサポートいたします。海外の専門性の高い開発者と連携し、最善のアプローチで製品を開発します。`,
              },
              {
                name: "クラウド, IoT, AI技術の活用",
                description: ` GCP, AWSなどのクラウドサービス,IoT,AIの最新技術を低コストで導入、運用します。`,
              }
            ],
          },
          {
            src: require("../assets/img/slider-6/slider-4.jpg"),
            position: "contact",
            title: "Contact US",
            desc: ` お気軽にお問い合わせください`,
            contactInfo: [
              {
                name: "Mail",
                to: "https://forms.gle/zZTpAv9tnHmwHscP9",
              },
              {
                name: "Address",
                desc: "〒108-0075 東京都港区港南4-1-6",
                to: "",
              },
            ],
          },
        ],
        settings: {
          vertical: true,
          mouseDrag: false,
          infiniteScroll: true,
          transition: 800,
        },
      };
    },
    methods: {
      iconSvg(icon) {
        return feather.icons[icon].toSvg();
      }
    },
    watch: {
      $route(to) {
        const param = to.params
        const index = this.sliderContent.map(i => i.position).indexOf(param.page)
        if (to.path === '/') { 
          this.$refs.carousel.slideTo(0);
        } else if (index === -1) {
          this.$router.push('/')
        } else {
          this.$refs.carousel.slideTo(index);
        }
      }
    }
  };
</script>

<style lang="scss" scoped>
  .subtitle {
    padding: 0 17%;
    @media only screen and (max-width: 767px) {
      padding: 0;
    }
  }
  .logos {
    align-items: center;
    @media only screen and (max-width: 767px) {
      margin-top: 40px;
    }
    .logo-img {
      height: auto;
      width: 70px;
      @media only screen and (max-width: 767px) {
        width: 40px;
      }
    }
    .logo-sign-img {
      height: auto;
      width: 100px;
      margin-left: 10px;
      @media only screen and (max-width: 767px) {
        margin-left: 5px;
        width: 70px;
      }
    }
  }
  .wrap-text {
    word-wrap : break-word;
    overflow-wrap : break-word;
    white-space: pre-line;
    line-break: auto;
    word-break: break-all;
  }
  .text-shadow {
    text-shadow:
      2px 2px 2px rgb(251, 251, 251), -2px -2px 2px rgb(251, 251, 251),
      -2px 2px 2px rgb(251, 251, 251),  2px -2px 2px rgb(251, 251, 251);
  }
  .google-map {
    position: fixed;
    left: 0;
    width: 100%;
  }
  .gmap_canvas {
    overflow: hidden;
    background: none !important;
    height: 800px;
    width: 100%;

    iframe {
      width: 100%;
      height: 100%;
      max-height: 200px;
      margin-top: 20px;
    }
  }
  .mapouter {
    position: relative;
    width: 100%;
    height: 100%;
  }
</style>
